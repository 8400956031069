<template>
  <DetailedPageContainer v-if="currentItem">
    <div class="detailed__images" v-if="currentItem.images.length > 0">
      <r-row :wrap="false" class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 4 }"
               v-for="(img, index) in currentItem.images"
               :key="index">
          <r-image :url="img.replace('small', 'medium')"/>
        </r-col>
      </r-row>
    </div>

    <div class="detailed__title">
      <r-row class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 12 }">
          <div class="detailed__category mozzarella metropolis--text" v-if="category">
            {{ category }}
          </div>

          <h2 class="ricotta harakiri--text">
            {{ currentItem.title }}
          </h2>
        </r-col>
      </r-row>
    </div>

    <r-row class="px-4">
      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 3 }">
        <SchedulePopover :schedule="currentItem.work_hours" />

        <div class="details" v-if="address">
          <r-icon icon="geopoint" fill="rocky"/>
          <span class="zamorano">
            {{ address }}
          </span>
        </div>

        <div v-if="currentItem.contacts">
          <Details v-for="(item, value) in currentItem.contacts" :key="item" :text="item" :icon="value" />
        </div >

        <QrCode v-if="website" :value="website"/>
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 3 }">
        <MoreText v-if="description" :text="description"/>
      </r-col>
    </r-row>

    <div class="detailed__map">
      <r-row class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 6 }">
          <PlaceMap :data="currentItem" :location="location" />
        </r-col>
      </r-row>
    </div>

    <div class="detailed__additional-info" v-if="additions">
      <AdditionalInfo :data="additions"/>
    </div>

    <div class="detailed__more-items">
      <MoreItemsList :title="$t('more.more_places')"
                     :category="currentItem.category.id"
                     type="places"
                     :data-set="GET_PLACES"
      />
    </div>
  </DetailedPageContainer>
</template>

<script>
import {mapGetters} from "vuex";
import DetailedPageContainer from "@/components/Containers/DetailedPageContainer";
import MoreText from "@/components/DetailedViewParts/MoreText/MoreText";
import QrCode from "@/components/DetailedViewParts/QrCode/QrCode";
import PlaceMap from "@/components/DetailedViewParts/PlaceMap/PlaceMap";
import AdditionalInfo from "@/components/DetailedViewParts/AdditionalInfo/AdditionalInfo";
import MoreItemsList from "@/components/DetailedViewParts/MoreItemsList/MoreItemsList";
import SchedulePopover from "@/components/DetailedViewParts/SchedulePopover/SchedulePopover";
import Details from "@/components/DetailedViewParts/Details/Details";

export default {
  name: "DetailedPlaceView",

  components: {
    Details,
    SchedulePopover,
    DetailedPageContainer,
    MoreText,
    QrCode,
    PlaceMap,
    AdditionalInfo,
    MoreItemsList,
  },

  computed: {
    ...mapGetters(['GET_PLACES',]),

    currentItem() {
      return this.GET_PLACES.filter(item => item.id == this.$route.params.id)[0];
    },

    location() {
      return this.currentItem?.location;
    },

    category() {
      return this.currentItem?.category?.title;
    },

    description() {
      return this.currentItem?.description.replace(/<[^>]*>?/gm, '');
    },

    address() {
      return this.currentItem?.address;
    },

    additions() {
      return this.currentItem?.additions;
    },

    website() {
      return this.currentItem?.contacts?.website;
    }
  },
}
</script>

<style lang="scss">

</style>
