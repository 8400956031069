<template>
  <div class="details" v-if="text">
    <r-icon :icon="whichIcon" fill="rocky" />
    <span class="zamorano">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Details",

  props: {
    icon: {
      type: String,
      default: () => '',
    },
    text: {
      type: String,
      default: () => '',
    },
  },

  computed: {
    whichIcon() {
      switch(this.icon) {
        case 'phone':
          return 'telephone';
        case 'email':
          return 'mail';
        case 'website':
          return 'link';
        default:
          return '';
      }
    },
  },
}
</script>

<style lang="scss">

</style>
