<template>
  <div class="schedule-popover" v-if="show">
    <r-icon icon="clock" fill="rocky"/>

    <r-popover>
      <template v-slot:activator="{ on }">
        <r-button-action v-on="on" :title="todaySchedule" size="briscola" is-arrow></r-button-action>
      </template>
      <template v-slot:content>
        <div class="schedule">
          <div v-for="(item, index) in week"
               :key="item.day"
               class="schedule__container"
          >
            <div class="schedule__day roquefort harakiri--text"
                 :class="{
              'rocky--text': index === dayOfWeek && !isClosed,
              'metropolis--text': index === dayOfWeek && isClosed,
            }">
              {{ item.day }}
            </div>

            <div class="schedule__time mozzarella harakiri--text"
                 :class="{
              'rocky--text': index === dayOfWeek && !isClosed,
              'metropolis--text': index === dayOfWeek && isClosed,
            }">
              {{ item.openTime }} {{ item.closeTime }}
            </div>
          </div>
        </div>
      </template>
    </r-popover>
  </div>
</template>

<script>
export default {
  name: "SchedulePopover",

  props: {
    schedule: {
      type: [Array, Object],
      default: () => [],
    },
  },

  computed: {
    show() {
      return Object.values(this.schedule).some(item => item !== null)
    },
    Day1() {
      const open = this.Day(this?.schedule?.day_1_open)
      const close = this.Day(this?.schedule?.day_1_close)
      return this.resultFormation(open, close, this.$t('dates.monday.short'))
    },
    Day2() {
      const open = this.Day(this?.schedule?.day_2_open)
      const close = this.Day(this?.schedule?.day_2_close)
      return this.resultFormation(open, close, this.$t('dates.tuesday.short'))
    },
    Day3() {
      const open = this.Day(this?.schedule?.day_3_open)
      const close = this.Day(this?.schedule?.day_3_close)
      return this.resultFormation(open, close, this.$t('dates.wednesday.short'))
    },
    Day4() {
      const open = this.Day(this?.schedule?.day_4_open)
      const close = this.Day(this?.schedule?.day_4_close)
      return this.resultFormation(open, close, this.$t('dates.thursday.short'))
    },
    Day5() {
      const open = this.Day(this?.schedule?.day_5_open)
      const close = this.Day(this?.schedule?.day_5_close)
      return this.resultFormation(open, close, this.$t('dates.friday.short'))
    },
    Day6() {
      const open = this.Day(this?.schedule?.day_6_open)
      const close = this.Day(this?.schedule?.day_6_close)
      return this.resultFormation(open, close, this.$t('dates.saturday.short'))
    },
    Day7() {
      const open = this.Day(this?.schedule?.day_7_open)
      const close = this.Day(this?.schedule?.day_7_close)
      return this.resultFormation(open, close, this.$t('dates.sunday.short'))
    },
    week() {
      return [
        this.Day1,
        this.Day2,
        this.Day3,
        this.Day4,
        this.Day5,
        this.Day6,
        this.Day7
      ]
    },
    todaySchedule() {
      const dow = this.$moment().weekday();
      const todaySchedule = this.week[dow];
      return `${this.$t('dates.today')} ${todaySchedule.openTime} ${todaySchedule.closeTime}`;
    },
    dayOfWeek() {
      const dow = this.$moment().weekday();
      return dow;
    },
    isClosed() {
      const currentTime = this.$moment();
      const dow = this.$moment().weekday();
      const todaySchedule = this.week[dow];
      const beforeTime = this.$moment(todaySchedule.openTime, 'HH:mm');
      const afterTime = this.$moment(todaySchedule.closeTime, 'HH:mm');

      if (currentTime.isBetween(beforeTime, afterTime) || todaySchedule.closeTime.length === 0) {
        return false;
      } else {
        return true;
      }
    }
  },

  methods: {
    Day(val) {
      if (val) {
        return this.$moment(val, 'HHmmss').format('HH:mm')
      }
      return null
    },
    resultFormation(open, close, name) {
      if (open === null && close === null) {
        return {
          day: `${name}`,
          openTime: this.$t('dates.day-off'),
          closeTime: ''
        }
      } else if (open === '00:00' && close === '00:00') {
        return {
          day: `${name}`,
          openTime: this.$t('dates.around-the-clock'),
          closeTime: ''
        }
      } else if (open === null && close !== null) {
        return {
          day: `${name}`,
          openTime: '-',
          closeTime: `${this.$t('dates.to')} ${close}`
        }
      } else if (open !== null && close === null) {
        return {
          day: `${name}`,
          openTime: `${this.$t('dates.from')} ${open}`,
          closeTime: '-'
        }
      } else {
        return {
          day: `${name}`,
          openTime: `${this.$t('dates.from')} ${open}`,
          closeTime: `${this.$t('dates.to')} ${close}`
        }
      }
    }
  }
}
</script>

<style lang="scss">
.schedule-popover {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .RIcon {
    margin-right: 8px;
  }
}

.schedule {
  padding: 16px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__day {
    margin-right: 12px;
  }

  &__time {

  }
}
</style>
