var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"schedule-popover"},[_c('r-icon',{attrs:{"icon":"clock","fill":"rocky"}}),_c('r-popover',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('r-button-action',_vm._g({attrs:{"title":_vm.todaySchedule,"size":"briscola","is-arrow":""}},on))]}},{key:"content",fn:function(){return [_c('div',{staticClass:"schedule"},_vm._l((_vm.week),function(item,index){return _c('div',{key:item.day,staticClass:"schedule__container"},[_c('div',{staticClass:"schedule__day roquefort harakiri--text",class:{
            'rocky--text': index === _vm.dayOfWeek && !_vm.isClosed,
            'metropolis--text': index === _vm.dayOfWeek && _vm.isClosed,
          }},[_vm._v(" "+_vm._s(item.day)+" ")]),_c('div',{staticClass:"schedule__time mozzarella harakiri--text",class:{
            'rocky--text': index === _vm.dayOfWeek && !_vm.isClosed,
            'metropolis--text': index === _vm.dayOfWeek && _vm.isClosed,
          }},[_vm._v(" "+_vm._s(item.openTime)+" "+_vm._s(item.closeTime)+" ")])])}),0)]},proxy:true}],null,false,2481695180)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }